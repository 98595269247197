import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link, withPrefix } from "gatsby"

import { handelize } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ArtworkCaption = ({
  Artist,
  Name,
  Year,
  Artwork_Type,
  Description,
  Height,
  Width,
  Unit_Price,
  Location,
  Status,
  Web_Description,
}) => (
  <>
    <p className="text-xs md:text-sm">
      {Name && <span className="italic">{Name}</span>}
      {Year && <>, {Year}</>}
      {Artwork_Type && <>, {Artwork_Type}</>}
      {Width && Height && (
        <>
          <br />
          {Height}" x {Width}"
        </>
      )}
      {Unit_Price && <>, ${Unit_Price}</>}
      {Status && <>, {Status}</>}
      {Location && <>, {Location}</>}
    </p>
    {Web_Description && (
      <p className="text-sm max-w-lg mx-auto">{Web_Description}</p>
    )}
  </>
)

const ArtworkTemplate = props => {
  const { data } = props.data.airtable

  return (
    <Layout>
      <Helmet>
        <link
          type="text/css"
          rel="stylesheet"
          href={withPrefix("magiczoomplus.css")}
        />
        <script src={withPrefix("magiczoomplus.js")} type="text/javascript" />
      </Helmet>
      <SEO title={data.Name} />
      <div className="max-w-6xl mx-auto px-4 my-8 w-full">
        <div className="text-center">
          <h1 className="mt-4 mb-2">{data.Name}</h1>
          {data.Artist && (
            <h4 className="mb-4">By {data.Artist[0].data.Name}</h4>
          )}
        </div>
        {data.largeImages &&
          data.largeImages.localFiles
            .filter(
              file =>
                file.childImageSharp &&
                file.childImageSharp.fixed &&
                file.childImageSharp.fixed.src
            )
            .map((largeFile, i) => {
              const smallImage =
                data.smallImages.localFiles[i].childImageSharp.fixed.src
              const largeImage = largeFile.childImageSharp.fixed.src

              return (
                <div key={i} className="text-center mb-4">
                  <a
                    href={largeImage}
                    className="MagicZoom"
                    data-options="zoomWidth:200px; zoomHeight:200px"
                  >
                    <img src={smallImage} alt={data.Name} />
                  </a>
                </div>
              )
            })}

        <div className="text-center">
          <ArtworkCaption {...data} />
        </div>
        {data.Artist && (
          <div className="text-center mt-8 flex justify-center">
            <Link
              to={`/${handelize(data.Artist[0].data.Name)}`}
              className="mx-4"
            >
              View Artist
            </Link>{" "}
            |
            <a
              href={`mailto:matt@mbrownfa.com?subject=Website inquiry about purchasing ${
                data.Name
              }${data.Artist ? ` by ${data.Artist[0].data.Name}` : ``}`}
              className="mx-4"
            >
              Purchase
            </a>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ArtworkTemplate

export const query = graphql`
  query ArtworkByRecordId($recordId: String!) {
    airtable(recordId: { eq: $recordId }) {
      data {
        Name
        largeImages: Images {
          localFiles {
            childImageSharp {
              fixed(width: 1200) {
                src
              }
            }
          }
        }
        smallImages: Images {
          localFiles {
            childImageSharp {
              fixed(width: 600) {
                src
              }
            }
          }
        }
        Year
        Web_Description
        Location
        Artwork_Type
        Height
        Width
        Status
        Unit_Price
        Artist {
          data {
            Name
          }
        }
      }
    }
  }
`
